import request from '../common/request';

// 扫码保存
export function addUserScanLog() {
  return request({
    url: '/api/wifi-info/wifiscanlog/addUserScanLog',
    method: 'post',
  })
}



