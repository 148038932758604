import request from '../common/request';
import { appKey ,clientId } from '../common/config';

// 获取微信公众号配置
export function getWechatConfig(url) {
  return request({
    url: '/api/blade-wechat/jssdk/config',
    method: 'get',
    params:{
			alias: 'wxoffiaccount',
			appKey,
			url,
      clientId
		}
  })
}



